<template>
  <div class="container-fluid">
    <MobileGuide />
    <SizeGuideListNavTab />
    <div class="card p-2">
      <div class="row p-1 align-items-center pb-5">
        <div class="col-md-6">
          <h2 class="roboto subtitle bold">Manage Size Guide</h2>
        </div>
      </div>
      <SizeGuideListTable @deleteCategorySize="deleteCategorySize" @refreshList="refreshList" :displayItems="displayItems" :items="items" />
    </div>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import SizeGuideListTable from "@/components/product/SizeGuideListTable";
import SizeGuideListNavTab from "@/components/product/SizeGuideListNavTab";
import MobileGuide from "@/components/navs/MobileGuide";

export default {
  name: "SizeGuideList",
  components: {
    MobileGuide,
    SizeGuideListTable,
    SizeGuideListNavTab,
  },
  data() {
    return {
      items: [],
      displayItems: [], // filtered array by brand & category
    };
  },
  watch: {
    items(newVal) {
      // make new Array() for displaying size guide by Brand & Category
      const filteredArr = newVal.filter(
        (thing, index, self) =>
          self.findIndex(
            (t) =>
              t.brand.name === thing.brand.name &&
              t.category.name === thing.category.name
          ) === index
      );
      this.displayItems = filteredArr.map((el) => ({
        brand_id: el.brand.id,
        brand_name: el.brand.name,
        category_id: el.category.id,
        category_name: el.category.name,
      }))
    }
  },
  mounted() {
    this.getCategorySize();
  },
  methods: {
    // Delete category size data in parent
    deleteCategorySize(id) {
      const index = this.items.findIndex((el) => el.id == id)
      this.items.splice(index, 1)
    },
    async getCategorySize() {
      try {
        const res = await this.$api.product.getCategorySize();
        if (res.status === 200) {
          this.items = res.data.data;
        } else toaster.make(res.data.message, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    refreshList() {
      this.items = []
      this.displayItems = []
      this.getCategorySize()
    },
  },
};
</script>
