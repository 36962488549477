<template>
  <b-modal
    id="modal-size-guide"
    title="Ring Size Guide"
    centered
    hide-header
    hide-footer
    size="lg"
    @hidden="resetError"
  >
    <b-table
      thead-tr-class="thead-light"
      thead-class="align-middle"
      :items="sizeGuideList"
      :fields="itemFields"
    >
      <template #cell(size_model)="data">
        <b-form-select
          v-model.number="$v.sizeGuideList.$model[data.index].size_id"
          placeholder="Sizes"
          :options="sizeList"
          :state="
            $v.sizeGuideList.$each[data.index].size_id.$error ? false : null
          "
          @change="filterSizeList"
          class="w-auto"
          v-if="!sizeGuideList[data.index].id"
        ></b-form-select>
        <span v-else>{{ data.value }}</span>
      </template>
      <template #cell(size)="data">
        <b-input-group>
          <b-form-input
            type="number"
            v-model.number="$v.sizeGuideList.$model[data.index].size"
            :state="
              $v.sizeGuideList.$each[data.index].size.$error ? false : null
            "
            class="input-size"
            min="0"
            @keypress="onlyNumber"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text class="bg-transparent">
              <b>mm</b>
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </template>
      <template  #cell(diameter)="data">
        <b-input-group>
          <b-form-input
            type="number"
            v-model.number="$v.sizeGuideList.$model[data.index].diameter"
            :state="
              $v.sizeGuideList.$each[data.index].size.$error ? false : null
            "
            class="input-size"
            min="0"
            @keypress="onlyNumber"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text class="bg-transparent">
              <b>mm</b>
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </template>
      <template #cell(description)="data">
        <b-form-input
          v-model.trim="sizeGuideList[data.index].description"
        ></b-form-input>
      </template>
      <template #cell(delete)="data">
        <b-button
          variant="danger"
          @click="data.item.id ? deleteCategorySize(data.item, data.index) : handleRows('remove', data.index)"
        >
          <font-awesome icon="trash"></font-awesome>
        </b-button>
      </template>
    </b-table>
    <b-alert show variant="danger" class="bold" v-if="errorMessage.size_model"
      >Size model tidak boleh kosong kosong</b-alert
    >
    <b-alert show variant="danger" class="bold" v-if="errorMessage.size"
      >Tolong isi keliling / diameter jika kedua nya masih kosong</b-alert
    >
    <div class="d-flex justify-content-between">
      <b-button variant="info" @click="handleRows('add')">
        <font-awesome icon="plus"></font-awesome> Add New Size
      </b-button>
      <b-button variant="info" @click="submitData">
        <font-awesome icon="save"></font-awesome> Save
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ModalSizeGuide",
  components: {
  },
  validations() {
    return {
      sizeGuideList: {
        $each: {
          size_id: { required },
          size: {
            required: requiredIf((self) => !self.size && !self.diameter),
          },
          diameter: {
            required: requiredIf((self) => !self.size && !self.diameter),
          },
        },
      },
    };
  },
  props: ["items", "brandId", "categoryId"],
  data() {
    return {
      itemFields: [
        {
          key: "size_model",
          label: "Size Model",
          thClass: "align-middle",
          tdClass: "align-middle",
        },
        {
          key: "size",
          label: "Keliling",
          thClass: "align-middle",
        },
        {
          key: "diameter",
          label: "Diameter",
          thClass: "align-middle",
        },
        {
          key: "description",
          label: "Description",
          thClass: "align-middle",
        },
        {
          key: "delete",
          label: "",
          thClass: "align-middle",
        },
      ],
      sizeGuideList: [], 
      oldSizeGuide: [], // old state for exisiting size guide
      sizeList: [], // size model list
      errorMessage: {
        size_model: false, // Size Model
        size: false, // Diameter & Keliling
      },
    };
  },
  watch: {
    items(newVal) {
      const mappedItems = newVal.map((el) => ({
        id: el.id,
        size_id: el.size_model.id,
        size_model: el.size_model.international,
        size: el.size_value,
        diameter: el.diameter,
        description: el.description,
      }));
      this.oldSizeGuide = JSON.parse(JSON.stringify(mappedItems));
      this.sizeGuideList = mappedItems;
      this.filterSizeList();
    },
  },
  mounted() {
    this.getSizes();
  },
  methods: {
    async getSizes() {
      try {
        const res = await this.$api.cms.getSizes();
        if (res.status === 200) {
          const sortedArray = res.data.data.sort((a, b) => a.eu - b.eu)
          this.sizeList = sortedArray.map((el) => ({
            value: el.id,
            text: el.international,
            disabled: false,
          }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    async submitData() {
      try {
        this.$v.$touch();
        if (this.$v.$error) {
          const obj = this.$v.sizeGuideList.$each.$iter;
          const errorSizeModel = Object.values(obj).find(
            (data) => data.size_id.$error
          );
          const errorSize = Object.values(obj).find(
            (data) => data.size.$error || data.diameter.$error
          );
          this.errorMessage.size_model = errorSizeModel ?? false;
          this.errorMessage.size = errorSize ?? false;
        } else {
          this.resetError();
          let newSizeGuide = this.sizeGuideList.filter((el) => !el.id); // For Bulk Save Product Category Size Guide(s)
          let existingSizeGuide = this.sizeGuideList.filter((el) => el.id); // Get Product Category Size Guide List form API Response (By Brand & Category)

          // Filter Changed Category Size Guide from Existing Category Size Guide
          const updatedSizeGuide = existingSizeGuide.filter(
            (el) =>
              !this.oldSizeGuide.some(
                (rel) =>
                  rel.size == el.size &&
                  rel.diameter == el.diameter &&
                  rel.description == el.description
              )
          );

          // No Change for Category Size Guide list
          if (!newSizeGuide.length && !updatedSizeGuide.length) {
            toaster.make("No Change for Size Guide List", "info");
            return;
          }

          // Save New Product Category Size Guide
          if (newSizeGuide.length) {
            newSizeGuide.forEach((el) => {
              el.brand_id = this.brandId;
              el.category_id = this.categoryId;
            });
            var saveResponse = await this.$api.product.saveCategorySize(
              newSizeGuide
            );
            if (saveResponse.status === 200) toaster.make("Size Guide List Updated", "success");
            else toaster.make(saveResponse.data.message, "danger")
          }

          // Update Product Category Size Guide
          if (updatedSizeGuide.length) {
            updatedSizeGuide.forEach((el) => {
              el.brand_id = this.brandId;
              el.category_id = this.categoryId;
            });
            var updateResponse = await this.$api.product.updateCategorySize(
              updatedSizeGuide
            );
            if (updateResponse.status === 200) toaster.make("New Size Guide Added", "success");
            else toaster.make(updateResponse.data.message, "danger")
          }

          // If saveCategorySize / updateCategorySize response === 200, refreshList for updating the sizeGuideList
          if (saveResponse?.status === 200 || updateResponse?.status === 200) {
            this.$bvModal.hide("modal-size-guide");
            this.$parent.$emit("refreshList");
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteCategorySize(data, index) {
      try {
        const res = await this.$api.product.deleteCategorySize(data.id);
        if (res.status === 200) {
          toaster.make(`Product category size model ${data.size_model} deleted`, "success");
          this.$parent.$emit("deleteCategorySize", data.id) // Delete category size data in parent component SizeGuideList.vue
          this.sizeGuideList.splice(index, 1) // Delete category size data in current child component
        } else toaster.make(res.data.message, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    // set disabled value for sizeList select option
    filterSizeList() {
      let arr = [] 
      this.sizeGuideList.forEach((el) => {
        if(el.size_id) arr.push(el.size_id)
      })
      this.sizeList.forEach((el) => {
        if(arr.includes(el.value)) el.disabled = true
        else el.disabled = false
      })
    },
    handleRows(type, index) {
      const arr = this.sizeGuideList
      if(type == 'add') {
        arr.push({
          size_id: null,
          size: null,
          diameter: null,
          description: ""
        });
      } else if(type == 'remove') {
        arr.splice(index, 1)
        this.filterSizeList();
      }
    },
    onlyNumber(e) {
      let keyCode = e.keyCode ? e.keyCode : e.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) e.preventDefault();
    },
    resetError() {
      this.errorMessage.size = false;
      this.errorMessage.size_model = false;
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input-size {
  max-width: 70px;
}
</style>
