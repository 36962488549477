<template>
  <div class="mb-5 text-nowrap border-bottom overflow-auto">
    <router-link to="/manage-size-guide" class="btn btn-wz-tab">Manage Size Guide</router-link>
    <router-link to="/add-size-guide" class="btn btn-wz-tab">Add Size Guide</router-link>
  </div>
</template>
<script>
export default {
  name: "SizeGuideListNavTab",
}
</script>
