<template>
  <div>
    <b-table-simple v-if="displayItems.length === 0">
      <b-thead class="thead-light">
        <b-tr>
          <b-th v-for="(field, i) in tableFields" :key="i">{{ field.label }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="text-center" :colspan="tableFields.length">No data in this list</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table thead-tr-class="thead-light" :items="displayItems" :fields="tableFields" v-else>
      <template #cell(size_guide)="data">
        <div class="btn btn-wz-variance" v-b-modal.modal-size-guide @click="setSelectedItems(data.item)">See Size Guide</div>
      </template>
    </b-table>
    <ModalSizeGuide :items="selectedItems" :brandId="selectedBrand" :categoryId="selectedCategory" /> 
  </div>
</template>
<script>
import ModalSizeGuide from "@/components/product/ModalSizeGuide";

export default {
  name: 'SizeGuideListTable',
  components: {
    ModalSizeGuide,
  },
  props: ['items', 'displayItems'],
  data() {
    return {
      selectedItems: {},
      selectedBrand: null,
      selectedCategory: null,
      tableFields: [
        {
          key: 'brand_name',
          label: 'Brand',
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: 'category_name',
          label: 'Category',
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: 'size_guide',
          label: 'Category Size Guide',
        },
      ]
    }
  },
  methods: {
    setSelectedItems(data) {
      this.selectedBrand = data.brand_id
      this.selectedCategory = data.category_id
      this.selectedItems = this.items.filter((el) =>
        el.brand.id === data.brand_id && el.category.id === data.category_id
      )
      // Sort ascending selected category size list by brand & category
      this.selectedItems.sort((a, b) => a.size_model.eu - b.size_model.eu)
    },
  },
}
</script>
<style scoped>
thead th, td {
  vertical-align: middle;
}
tbody {
  font-size: .865rem;
  white-space: nowrap;
}
tbody p {
  line-height: 22px;
  margin-bottom: .5rem;
}
.td-dropdown {
  padding: 1rem .25rem;
}
@media (min-width: 992px) {
  tbody {
    white-space: normal;
  }
  .table-responsive {
    overflow: initial;
  }
}
.mw-400px {
  max-width: 400px;
  text-align: justify;
  white-space: normal;
}
</style>
